const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#282c34',
        padding: '10px',
        marginBottom: '20px'
    },
    nav: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        margin: '0 10px',
        padding: '10px 20px',
        backgroundColor: '#61dafb',
        color: 'black',
        textDecoration: 'none',
        borderRadius: '5px',
        fontFamily: '"Roboto Mono", monospace'
    },
    input: {
        margin: '0 10px',
        padding: '10px 20px',
        backgroundColor: '#cfd8e3',
        color: 'black',
        textDecoration: 'none',
        borderRadius: '5px',
        fontFamily: '"Roboto Mono", monospace'
    },
    mainPanel: {
        flex: 1,
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#f0f0f0'
    },
    stuffPanel: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    stuffPaper: {
        display: 'flex',
        flexDirection: 'column',
        width: '600px',
        height: '400px',
        backgroundColor: '#cce8df',
        padding: '10px',
        borderRadius: '10px'
    }
} as const;

export default styles;
