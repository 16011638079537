import React, { useState } from 'react';
import styles from '../styles'

const StuffPage: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [atesmanScore, setAtesmanScore] = useState<number | null>(null);

    const calculateClicked = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const formData = new FormData();
        formData.append('file', selectedFile!)

        const result = await fetch('https://emretapci.com/readability', {
            method: 'post',
            headers: {
                'X-Custom-Header': 'x'
            },
            body: formData
        });

        setAtesmanScore((await result.json())['atesman-readability-score']);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        setSelectedFile(file);
    }

    const scoreAssessment: ({ min: number, assessment: string })[] = [
        {
            min: 90,
            assessment: 'Easily comprehensible by children of 4th grade and less'
        },
        {
            min: 80,
            assessment: 'Easily comprehensible by children of 5th and 6th grade'
        },
        {
            min: 70,
            assessment: 'Easily comprehensible by children of 7th and 8th grade'
        },
        {
            min: 60,
            assessment: 'Easily comprehensible by children of 9th and 10th grade'
        },
        {
            min: 50,
            assessment: 'Easily comprehensible by children of 11th and 12th grade'
        },
        {
            min: 40,
            assessment: 'Easily comprehensible by adults of associate degree (önlisans)'
        },
        {
            min: 30,
            assessment: 'Easily comprehensible by adults of undergraduate degree (lisans)'
        },
        {
            min: Number.MIN_VALUE,
            assessment: 'Easily comprehensible by adults of graduate degree (yüksek lisans)'
        }
    ];

    return (
        <div style={styles.stuffPanel}>
            <div style={styles.stuffPaper}>
                <div style={styles.button}>
                    Ateşman Readability Score Calculator
                </div>
                <div style={{ height: '30px' }}></div>
                <input
                    type='file'
                    style={styles.input}
                    onChange={handleFileChange}
                ></input>
                <div style={{ height: '10px' }}></div>
                <div style={{
                    fontFamily: '"Roboto Mono", monospace'
                }}>
                    The file must be in plaintext format encoded with UTF-8.
                    No .pdf, .doc, .docx, or any other non-plaintext format is supported yet.
                </div>
                <div style={{ height: '30px' }}></div>
                <button
                    style={{
                        ...styles.button,
                        width: '140px'
                    }}
                    onClick={calculateClicked}
                    disabled={selectedFile === null}
                >
                    Calculate
                </button>
                {atesmanScore !== null &&
                    <>
                        <div style={{ height: '30px' }}></div>
                        <div style={{
                            fontFamily: '"Roboto Mono", monospace'
                        }}>
                            {`Ateşman Readability Score: ${atesmanScore}`}
                            <p />
                            {scoreAssessment.find((assessment: { min: number, assessment: string }) => atesmanScore >= assessment.min)?.assessment || ''}
                        </div>
                    </>
                }
                <div style={{ height: '100px' }}></div>
                <div style={{
                    fontFamily: '"Roboto Mono", monospace'
                }}>
                    This calculation is based on the academic work documented in <a href="https://dergipark.org.tr/tr/download/article-file/589339">this</a> article
                </div>
            </div>
        </div>
    );
};

export default StuffPage;
